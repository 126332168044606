const main = () => {
  (() => {
    let blog = document.getElementById("blog");
    fetch("https://blog.licon-iiis.com/wp-json/wp/v2/posts?_embed&per_page=3")
      .then((Response) => {
        if (Response.ok) {
          return Response.json();
        }
        throw new Error(`リクエスト失敗 : ${Response.status}`);
      })
      .then((data) => {
        data.forEach((item) => {
          if (!item.featured_media) {
            item._embedded["wp:featuredmedia"] = [];
          }
          const date = new Date(item.date);
          const yy = date.getFullYear();
          const mm = ("0" + (date.getMonth() + 1)).slice(-2);
          const dd = ("0" + date.getDate()).slice(-2);

          // 抜粋からHTMLタグを削除する
          const parser = new DOMParser();
          const excerptDoc = parser.parseFromString(
            item.excerpt.rendered,
            "text/html"
          );
          let excerptText = excerptDoc.body.textContent || "";
          excerptText = excerptText.replace(/\[…\]/g, "...");

          let html = `
                    <div class="c-card--blog">
                        <a href= "${item.link}" target="_blank" rel="noopener noreferrer" class="c-card--blog__link">
                            <div class="c-card--blog__img">
                              <img src="${item._embedded["wp:featuredmedia"][0].source_url}">
                            </div>
                            <div class="c-card--blog__body">
                                <p class="c-card--blog__date">${yy}.${mm}.${dd}</p>
                                <h3 class="c-card--blog__title">${item.title.rendered}</h3>
                                <p class="c-card--blog__text">${excerptText}</p>                            </div>
                        </a>
                    </div>`;
          blog.innerHTML += html;
        });
      })
      .catch((e) => {
        blog.innerHtml += "記事がありません。";
      });
  })();
};

window.addEventListener("DOMContentLoaded", main);
